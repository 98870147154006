import React from "react";
import {
  useAppDispatch,
  forgeViewerInitializeThunk,
  useAppSelector,
} from "store";

export function useForgeViewer() {
  const { viewer } = useAppSelector((state) => state.forge);
  const dispatch = useAppDispatch();
  const viewerRenderer = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>, urn: string, glTF?: string) => {
      const el = ref.current;
      console.log({ urn, glTF, condition: glTF?.startsWith("urn") });
      if (el) {
        if (urn.startsWith("urn")) {
          dispatch(forgeViewerInitializeThunk({ el, urn }));
        } else {
          dispatch(forgeViewerInitializeThunk({ el, urn: "", glTF: urn }));
        }
      }
    },
    [dispatch]
  );
  return { viewer, viewerRenderer };
}
