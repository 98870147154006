import React from "react";
import { styled } from "@mui/material";
import Draggable from "react-draggable";
import { useAppSelector } from "store";

// 3D urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlOEIlRTElODUlQTclRTElODYlQTglRTElODQlODklRTElODUlQTElRTElODYlQjclRTElODQlODMlRTElODUlQTklRTElODYlQkMlMjA3MjEtMzAlMjAlRTElODQlODAlRTElODUlQjMlRTElODYlQUIlRTElODQlODUlRTElODUlQjUlRTElODYlQUIlRTElODQlODklRTElODUlQTIlRTElODYlQkMlRTElODQlOTIlRTElODUlQUElRTElODYlQUYlRTElODQlODklRTElODUlQjUlRTElODQlODklRTElODUlQTUlRTElODYlQUYtVGQ5ak02QU56Lm53Yw
// 2D urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi9BLTIwMV8yMTUlMjAlRTElODQlOTElRTElODUlQTclRTElODYlQkMlRTElODQlODYlRTElODUlQTclRTElODYlQUIlRTElODQlODMlRTElODUlQTktU2tKTWZseTZnLmR3Zw
interface ForgeViewerPropType extends React.HTMLProps<HTMLDivElement> {
  urn: string;
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  viewerRenderer(ref: React.RefObject<HTMLDivElement>, urn: string): void;
}

export function ForgeViewer(props: ForgeViewerPropType) {
  const { urn, viewer, viewerRenderer, ...rest } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (viewer) {
      viewer.tearDown();
    }
    viewerRenderer(ref, urn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, viewerRenderer, urn]);
  return <div ref={ref} id="forgeViewer" {...rest}></div>;
}

////
interface ForgePnPViewerProps extends React.HTMLProps<HTMLDivElement> {
  urn2D: string;
  urn3D: string;
  viewer2D: Autodesk.Viewing.GuiViewer3D | null;
  viewer3D: Autodesk.Viewing.GuiViewer3D | null;
  viewerRenderer(
    ref3D: React.RefObject<HTMLDivElement>,
    ref2D: React.RefObject<HTMLDivElement>,
    urn3D: string,
    urn2D: string
  ): void;
}

const Viewer2D = styled("div")`
  .draggable {
    left: 68%;
    top: 64%;
    width: 30%;
    height: 50%;
    max-width: 400px;
    max-height: 300px;
    z-index: 99999;
    position: absolute;

    // -moz-box-shadow: 1 3px 6px -3px gray;
    // -webkit-box-shadow: 1 3px 6px -3px gray;
    border: 1px solid #adadad;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
  }

  .draggable-nav {
    height: 15px;
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 109;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // -moz-box-shadow: 0 3px 5px -3px black;
    // -webkit-box-shadow: 0 3px 5px -3px black;
    // box-shadow: 0 3px 5px -3px black;
  }

  .draggable-body {
    top: 15px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    z-index: 108;
    border: 1px solid #adadad;
    // border: black 1px solid;
    // border: 2px solid #adadad;
  }
`;

export function ForgePnPViewer(props: ForgePnPViewerProps) {
  const { urn2D, urn3D, viewer2D, viewer3D, viewerRenderer, ...rest } = props;
  const ref3D = React.useRef<HTMLDivElement>(null);
  const ref2D = React.useRef<HTMLDivElement>(null);
  const { loading } = useAppSelector((state) => state.forge);

  React.useEffect(() => {
    if (viewer3D) {
      viewer3D.tearDown();
    }
    if (viewer2D) {
      viewer2D.tearDown();
    }
    viewerRenderer(ref3D, ref2D, urn3D, urn2D);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref3D, ref2D, viewerRenderer, urn3D, urn2D]);
  return (
    <div {...rest}>
      <div ref={ref3D}></div>
      <Viewer2D
        sx={{
          width: "100%",
          height: "100%",
          opacity: loading ? 0 : 1,
          zIndex: 9999999,
        }}
      >
        <Draggable>
          <div className="draggable">
            <div className="draggable-nav"></div>
            <div className="draggable-body">
              <div ref={ref2D}></div>
            </div>
          </div>
        </Draggable>
      </Viewer2D>
    </div>
  );
}
