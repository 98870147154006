import React from "react";
import { BrowserRouter } from "react-router-dom";
import { BuilderhubThemeProvider } from "../ThemeProvider";
import { StoreProvider } from "../StoreProvider";

export function RootProvider({ children }: { children: React.ReactNode }) {
  return (
    <StoreProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <BuilderhubThemeProvider>{children}</BuilderhubThemeProvider>
      </BrowserRouter>
    </StoreProvider>
  );
}
