import React from "react";
import { ForgeViewer } from "components";
import { useForgeViewer } from "hooks";
import { useAppSelector } from "store";

export function Viewer() {
  const { viewer, viewerRenderer } = useForgeViewer();
  const { urn } = useAppSelector((state) => state.forge);
  return urn ? (
    <ForgeViewer
      urn={urn}
      viewer={viewer}
      viewerRenderer={viewerRenderer}
      style={{
        position: "relative",
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
    />
  ) : null;
}
