import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { forgeViewerInitializeThunk } from "./thunks";
export * from "./thunks";

interface State {
  loading: boolean;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  error: boolean;
  message: string;
  doc: Autodesk.Viewing.Document | null;
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  urn: string | null;
  gltf: string | null;
}

const initialState: State = {
  loading: false,
  accessToken: "",
  tokenType: "",
  expiresIn: 0,
  error: false,
  message: "",
  doc: null,
  viewer: null,
  urn: null,
  gltf: null,
};

export const forgeSlice = createSlice({
  name: "autudeskForge",
  initialState,
  reducers: {
    setUrn3D(state, action: PayloadAction<string>) {
      state.urn = action.payload;
    },
    setGlTF(state, action: PayloadAction<string>) {
      state.gltf = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(forgeViewerInitializeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgeViewerInitializeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.tokenData.access_token;
        state.tokenType = action.payload.tokenData.token_type;
        state.expiresIn = action.payload.tokenData.expires_in;
        state.doc = action.payload.doc;
        // @ts-ignore
        state.viewer = action.payload.viewer;
      })
      .addCase(forgeViewerInitializeThunk.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        state.message = response.error.message || "";
      });
  },
});

export const { setUrn3D } = forgeSlice.actions;
