function Builderhub(
  this: Autodesk.Viewing.Extension,
  viewer: Autodesk.Viewing.GuiViewer3D,
  options: any
) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
}

Builderhub.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
Builderhub.prototype.constructor = Builderhub;

Builderhub.prototype.load = function () {
  const viewer = this.viewer as Autodesk.Viewing.GuiViewer3D;

  alert("Builderhub is WUUTWUT!");
  // console.log({ viewer });
  viewer.setNavigationLock(true);
  return true;
};

Builderhub.prototype.unload = function () {
  const viewer = this.viewer as Autodesk.Viewing.GuiViewer3D;
  alert("Builderhub is now unloaded!");
  viewer.setNavigationLock(false);
  return true;
};

Autodesk.Viewing.theExtensionManager.registerExtension(
  "Builderhub",
  Builderhub
);
////
// const getGeoList = ( model: Autodesk.Viewing.Model, dbIds: number[]) =>
// {
//   const tree = model.getInstanceTree();
//   const frags = model.getFragmentList();
//   for (const dbid of dbIds) {
//     tree.enumNodeFragments(dbid, (fragid: number) => {
//       //@ts-ignore
//       let what = frags.getGeometry(fragid)  
//       console.log("WHAT", what)
//     });
//   }
// }

// function listFragmentProperties(model: Autodesk.Viewing.Model,fragId : number) {
//   console.log('Fragment ID:', fragId);
//   const frags = model.getFragmentList();
//   // Get IDs of all objects linked to this fragment
//   //@ts-ignore
//   const objectIds = frags.getDbIds(fragId);
//   console.log('Linked object IDs:', objectIds);
//   // Get the fragment's world matrix
//   let matrix = new THREE.Matrix4();
//   //@ts-ignore
//   frags.getWorldMatrix(fragId, matrix);
//   console.log('World matrix:', JSON.stringify(matrix));
//   // Get the fragment's world bounds
//   let bbox = new THREE.Box3();
//   frags.getWorldBounds(fragId, bbox);
//   console.log('World bounds:', JSON.stringify(bbox));
// } 

const setMaterial = ( model: Autodesk.Viewing.Model, dbIds: number[], material: any) => {
  //@ts-ignore
  model.unconsolidate(); // gltf 는 unconsolidate 을 하지 않아도 변화가 가능하지만 nwc 나 revit 모델들은 unconsolidate 을 하지 않으면 변화가 없다.
  const tree = model.getInstanceTree();
  const frags = model.getFragmentList();
  for (const dbid of dbIds) {
    tree.enumNodeFragments(dbid, (fragid: number) => {
      //@ts-ignore
      frags.setMaterial(fragid, material);
    });
  }
};

// const changeTexture = (viewer: { impl: { getMaterials: () => any; }; },selectedList: any, texture: THREE.MeshBasicMaterial) => {
//   // let selectedList = viewer.getAggregateSelection();
//   const materialMgr = viewer.impl.getMaterials();
// materialMgr.addMaterial(
//   'textureMaterial',
//   texture,
//   true,
// );

// for (let currentGroup of selectedList) {
//   setMaterial(
//     currentGroup.model,
//     currentGroup.selection,
//     texture
//   );
// }
// }

// Testing custom toolbar...and it works!
function BuilderhubToolbarExtension(
  this: Autodesk.Viewing.Extension,
  viewer: Autodesk.Viewing.GuiViewer3D,
  options: any
) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
}

BuilderhubToolbarExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
);
BuilderhubToolbarExtension.prototype.constructor = BuilderhubToolbarExtension;

BuilderhubToolbarExtension.prototype.load = function () {
  //this.viewer.fitToView();

  return true;
};

BuilderhubToolbarExtension.prototype.unload = function () {
  if (this.subToolbar) {
    this.viewer.toolbar.removeControl(this.subToolbar);
    this.subToolbar = null;
  }
};

BuilderhubToolbarExtension.prototype.onToolbarCreated = function (toolbar: {
  addControl: (arg0: any) => void;
}) {
  let viewer = this.viewer;
  // const spongetemp = THREE.ImageUtils.loadTexture('ssmain.jpeg')
  // spongetemp.wrapS = THREE.RepeatWrapping;
  // spongetemp.wrapT = THREE.RepeatWrapping;
  // let factor = 10;
  // spongetemp.repeat.set(factor, factor);
  // const spongeTexture = new THREE.MeshBasicMaterial({ map: spongetemp })
  const spongeTexture = new THREE.MeshBasicMaterial({ map: THREE.ImageUtils.loadTexture('ssmain.jpeg') })
  const sangchuTexture = new THREE.MeshBasicMaterial({ map: THREE.ImageUtils.loadTexture('sangchu.png') })
  const brickTexture = new THREE.MeshBasicMaterial({ map: THREE.ImageUtils.loadTexture('wall1.jpg') })
  const steelTexture = new THREE.MeshBasicMaterial({ map: THREE.ImageUtils.loadTexture('steel.jpg') })
  const woodTexture = new THREE.MeshBasicMaterial({ map: THREE.ImageUtils.loadTexture('wood.jpg') })

  let comboButton = new Autodesk.Viewing.UI.ComboButton('custom-combo-button');
  comboButton.setToolTip('텍스처 변경');
    //@ts-ignore
    comboButton.icon.style = `background-image: url('ceramicTiles.jpeg'); background-size: cover;`;
  comboButton.addClass("custom-combo-button")


// Button 1
let button1 = new Autodesk.Viewing.UI.Button('spongebob-texture');
  button1.onClick = function (e) {
    let selectedList = viewer.getAggregateSelection();
    let geo = selectedList[0].model.getFragmentList().getGeometry(selectedList[0].selection[0])
    let geo2 = selectedList[0].model.getFragmentList().getGeometry(selectedList[0].selection[1])
    let geo3 = selectedList[0].model.getFragmentList().getGeometry(selectedList[0].selection[2])
    let geo4 = selectedList[0].model.getFragmentList().getGeometry(selectedList[0].selection[3])

    console.log("LIST",selectedList)
    console.log("GEO", geo)
    console.log("GEO2", geo2)
    console.log("GEO3", geo3)
    console.log("GEE4", geo4)

    const materialMgr = viewer.impl.getMaterials();
  materialMgr.addMaterial(
    'textureMaterial',
    spongeTexture,
    true,
  );
  for (let currentGroup of selectedList) {
    setMaterial(
      currentGroup.model,
      currentGroup.selection,
      spongeTexture
    );
  }
  };
  button1.addClass('spongebob-texture');
  //@ts-ignore
  button1.icon.style = `background-image: url('ssmain.jpeg'); background-size: cover;`;
  button1.setToolTip('스폰지밥 텍스처');
  comboButton.addControl(button1);

// Button 2
let button2 = new Autodesk.Viewing.UI.Button('sangchu-texture');
  button2.onClick = function (e) {
    let selectedList = viewer.getAggregateSelection();
    const materialMgr = viewer.impl.getMaterials();
  materialMgr.addMaterial(
    'textureMaterial',
    sangchuTexture,
    true,
  );
  for (let currentGroup of selectedList) {
    setMaterial(
      currentGroup.model,
      currentGroup.selection,
      sangchuTexture
    );
  }
  };
  
  button2.addClass('sangchu-texture');
  //@ts-ignore
  button2.icon.style = `background-image: url('sangchu.png'); background-size: cover;`;
  button2.setToolTip('상추 텍스처');
  comboButton.addControl(button2);
  
// Button 3
let button3 = new Autodesk.Viewing.UI.Button('brick-texture');
button3.onClick = function (e) {
  let selectedList = viewer.getAggregateSelection();
  const materialMgr = viewer.impl.getMaterials();
materialMgr.addMaterial(
  'textureMaterial',
  brickTexture,
  true,
);
for (let currentGroup of selectedList) {
  setMaterial(
    currentGroup.model,
    currentGroup.selection,
    brickTexture
  );
}
};
  button3.addClass('brick-texture');
  //@ts-ignore
  button3.icon.style = `background-image: url('wall1.jpg'); background-size: cover;`;
  button3.setToolTip('벽돌 텍스처');
  comboButton.addControl(button3);
  
// Button 4
let button4 = new Autodesk.Viewing.UI.Button('steel-texture');
button4.onClick = function (e) {
  let selectedList = viewer.getAggregateSelection();
  const materialMgr = viewer.impl.getMaterials();
materialMgr.addMaterial(
  'textureMaterial',
  steelTexture,
  true,
);
for (let currentGroup of selectedList) {
  setMaterial(
    currentGroup.model,
    currentGroup.selection,
    steelTexture
  );
}
};
  button4.addClass('steel-texture');
  //@ts-ignore
  button4.icon.style = `background-image: url('steel.jpg'); background-size: cover;`;
  button4.setToolTip('강철 텍스처');
  comboButton.addControl(button4);
  
// Button 5
let button5 = new Autodesk.Viewing.UI.Button('wood-texture');
button5.onClick = function (e) {
  let selectedList = viewer.getAggregateSelection();
  const materialMgr = viewer.impl.getMaterials();
materialMgr.addMaterial(
  'textureMaterial',
  woodTexture,
  true,
);
for (let currentGroup of selectedList) {
  setMaterial(
    currentGroup.model,
    currentGroup.selection,
    woodTexture
  );
}
};
  button5.addClass('wood-texture');
  //@ts-ignore
  button5.icon.style = `background-image: url('wood.jpg'); background-size: cover;`;
  button5.setToolTip('나무 텍스처');
  comboButton.addControl(button5);

  // SubToolbar
  this.subToolbar = new Autodesk.Viewing.UI.ControlGroup("my-custom-toolbar");
  this.subToolbar.addControl(comboButton);
  toolbar.addControl(this.subToolbar);
};

Autodesk.Viewing.theExtensionManager.registerExtension(
  "BuilderhubToolbarExtension",
  BuilderhubToolbarExtension
);

export {};
