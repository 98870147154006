import React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useAppSelector } from "store";
import { Viewer } from "./Viewer";
import { Content } from "./Content";

export function Extension() {
  const { loading } = useAppSelector((state) => state.forge);
  return (
    <Grid
      sx={{
        width: "100vw",
        height: { lg: "100vh", xs: "auto" },
        p: { lg: "0 0", xs: "0 1rem" },
        position: "relative",
      }}
      container
    >
      <Grid
        item
        sx={{
          // width: { lg: "58.33%", xs: "100%" },
          width: { lg: "80%", xs: "100%" },
          height: { lg: "100%", xs: "40vh" },
          pt: "64px",
          position: { lg: "fixed", xs: "unset" },
          top: 0,
        }}
        lg={10}
      >
        {loading && (
          <CircularProgress
            sx={{
              position: "absolute",
              marginLeft: "50%",
              marginTop: "50%",
            }}
          />
        )}
        <Viewer />
      </Grid>
      <Grid
        item
        sx={{
          // marginLeft: { lg: "58.33%", xs: 0 },
          marginLeft: { lg: "80%", xs: 0 },
          width: "100%",
        }}
        lg={2}
      >
        <div>
          <Content />
        </div>
      </Grid>
    </Grid>
  );
}
