import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import { useAppDispatch, useAppSelector, setUrn3D } from "store";
import "./Extensions";

const defaultUrn =
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlODklRTElODUlQTklRTElODQlODAlRTElODUlQjIlRTElODQlODYlRTElODUlQTklMjAlRTElODQlODAlRTElODUlQTUlRTElODYlQUIlRTElODQlODYlRTElODUlQUUlRTElODYlQUYlMjAlRTElODQlODklRTElODUlQTIlRTElODYlQjclRTElODQlOTElRTElODUlQjMlRTElODYlQUZfJUUxJTg0JThFJUUxJTg1JUExJUUxJTg2JUJDJUUxJTg0JTg2JUUxJTg1JUFFJUUxJTg2JUFCTy1JOVA5S3ROZ2wubndj";

const data = [
  {
    id: 1,
    name: "Builderhub Extension",
    extensionName: "Builderhub",
    urn: defaultUrn,
  },

  {
    id: 2,
    name: "CUSTOM TOOLBAR",
    extensionName: "BuilderhubToolbarExtension",
    urn: defaultUrn,
  },
];

export function Content() {
  const dispatch = useAppDispatch();
  const { viewer } = useAppSelector((state) => state.forge);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    dispatch(setUrn3D(defaultUrn));
  }, [dispatch]);
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      aria-label="contacts"
    >
      {data.map((item) => {
        // const handleClick = () => {
        //   if (viewer) {
        //     viewer.loadExtension("Autodesk.Viewing.ZoomWindow").then(() => {
        //       const api = viewer.getExtension("Autodesk.Viewing.ZoomWindow");
        //       api.activate("zoomwindow");
        //     });
        //     viewer.loadExtension(item.extensionName);
        //   }
        //   const extensions =
        //     Autodesk.Viewing.theExtensionManager.getRegisteredExtensions();
        //   console.log({ extensions });
        //   dispatch(setUrn3D(item.urn));
        //   setSelectedIndex(item.id);
        // };
        const handleClick = () => {
          if (viewer) {
            viewer.loadExtension(item.extensionName);
          }
          dispatch(setUrn3D(item.urn));
          setSelectedIndex(item.id);
        };
        return (
          <ListItem disablePadding key={item.id}>
            <ListItemButton
              onClick={handleClick}
              selected={selectedIndex === item.id}
            >
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        );
      })}
      {/* <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Chelsea Otakan" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText inset primary="Eric Hoffman" />
        </ListItemButton>
      </ListItem> */}
    </List>
  );
}
