import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";
import { useAppDispatch, setUrn3D } from "store";

const data = [
  {
    id: 0,
    name: "NWC: 콘크리트, 철근, 거푸집",
    fileType: "nwc",
    // urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlOTElRTElODUlQjMlRTElODYlQUYlRTElODQlODUlRTElODUlQTIlRTElODYlQkElRTElODQlOTElRTElODUlQTklRTElODYlQjdfJUUxJTg0JTgyJUUxJTg1JUExJUUxJTg2JUI3JUUxJTg0JThCJUUxJTg1JUEzJUUxJTg2JUJDJUUxJTg0JThDJUUxJTg1JUFFJUUxJTg0JTg5JUUxJTg1JUI1JTIwJUUxJTg0JThDJUUxJTg1JUI1JUUxJTg2JUFCJUUxJTg0JThDJUUxJTg1JUE1JUUxJTg2JUFCJUUxJTg0JThCJUUxJTg1JUIzJUUxJTg2JUI4JTIwJUUxJTg0JThCJUUxJTg1JUE5JUUxJTg0JTkxJUUxJTg1JUI1JUUxJTg0JTg5JUUxJTg1JUIzJUUxJTg0JTkwJUUxJTg1JUE2JUUxJTg2JUFGMi0wclY4V0VUaTQubndj",
    urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8yMDIzMDQwMy02NWt5RDdlRmcubndj",
    // urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi85ZTZiZmUzZi03YTM4LTQ0ZGUtYWQ1MC0wYzYzZTNmMmUxM2QtRnVQdll0TFJTLmRhZQ",
    renderer: "forge",
  },
  {
    id: 1,
    name: "glTF: 콘크리트, 철근, 거푸집",
    fileType: "gltf",
    urn: "project/01/model.gltf",
    renderer: "forge",
  },
  {
    id: 2,
    name: "glTF: NO_TEXTURE 콘크리트, 철근, 거푸집",
    fileType: "gltf",
    urn: "project/02/model.gltf",
    renderer: "forge",
  },
  // {
  //   id: 0,
  //   name: "Cat Excavator 392F",
  //   fileType: "gltf",
  //   urn: "Cat Excavator 391F.gltf",
  //   renderer: "forge",
  // },
  {
    id: 3,
    name: "Builderhub gltf: Form Work Test",
    fileType: "gltf",
    urn: "formworktest_out/formworktest.gltf",
    renderer: "forge",
  },
  {
    id: 4,
    name: "영천동",
    fileType: "gltf",
    urn: "project/03/BH-A_FIN.glTF.gltf",
    renderer: "forge",
  },
  // {
  //   id: 2,
  //   name: "Revit: 단위세대 바닥 무늬 변경",
  //   fileType: "revit",
  //   urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlODMlRTElODUlQTElRTElODYlQUIlRTElODQlOEIlRTElODUlQjElRTElODQlODklRTElODUlQTYlRTElODQlODMlRTElODUlQTIlMjAlRTElODQlODclRTElODUlQTElRTElODQlODMlRTElODUlQTElRTElODYlQTglMjAlRTElODQlODYlRTElODUlQUUlRTElODQlODIlRTElODUlQjQlRTElODQlODclRTElODUlQTclRTElODYlQUIlRTElODQlODAlRTElODUlQTclRTElODYlQkMtZGNqQWE3RUJJLnJ2dA",
  //   renderer: "forge",
  // },
  // {
  //   id: 3,
  //   name: "NWC: 단위세대 바닥 무늬 변경",
  //   fileType: "nwc",
  //   urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlODMlRTElODUlQTElRTElODYlQUIlRTElODQlOEIlRTElODUlQjElRTElODQlODklRTElODUlQTYlRTElODQlODMlRTElODUlQTIlMjAlRTElODQlODclRTElODUlQTElRTElODQlODMlRTElODUlQTElRTElODYlQTglMjAlRTElODQlODYlRTElODUlQUUlRTElODQlODIlRTElODUlQjQlRTElODQlODclRTElODUlQTclRTElODYlQUIlRTElODQlODAlRTElODUlQTclRTElODYlQkMtZTFoVGctQUlKLm53Yw",
  //   renderer: "forge",
  // },
  // {
  //   id: 4,
  //   name: "Revit: 소규모 건물 (색상 일치)",
  //   fileType: "revit",
  //   urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlODklRTElODUlQTklRTElODQlODAlRTElODUlQjIlRTElODQlODYlRTElODUlQTklMjAlRTElODQlODAlRTElODUlQTUlRTElODYlQUIlRTElODQlODYlRTElODUlQUUlRTElODYlQUYlMjAlRTElODQlODklRTElODUlQTIlRTElODYlQjclRTElODQlOTElRTElODUlQjMlRTElODYlQUYtT0ttNm9SY1htLnJ2dA",
  //   renderer: "forge",
  // },
  // {
  //   id: 5,
  //   name: "Revit: 소규모 건물 (재질 보기로 저장)",
  //   fileType: "revit",
  //   urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlODklRTElODUlQTklRTElODQlODAlRTElODUlQjIlRTElODQlODYlRTElODUlQTklMjAlRTElODQlODAlRTElODUlQTUlRTElODYlQUIlRTElODQlODYlRTElODUlQUUlRTElODYlQUYlMjAlRTElODQlODklRTElODUlQTIlRTElODYlQjclRTElODQlOTElRTElODUlQjMlRTElODYlQUZfJTIwJUUxJTg0JThDJUUxJTg1JUEyJUUxJTg0JThDJUUxJTg1JUI1JUUxJTg2JUFGJUUxJTg0JTg3JUUxJTg1JUE5JUUxJTg0JTgwJUUxJTg1JUI1JUUxJTg0JTg1JUUxJTg1JUE5JUUxJTg0JThDJUUxJTg1JUE1JUUxJTg0JThDJUUxJTg1JUExJUUxJTg2JUJDLXF2S1k3QXVyTi5ydnQ",
  //   renderer: "forge",
  // },
  // {
  //   id: 6,
  //   name: "NWC: 소규모 건물 (창문 O)",
  //   fileType: "nwc",
  //   urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlODklRTElODUlQTklRTElODQlODAlRTElODUlQjIlRTElODQlODYlRTElODUlQTklMjAlRTElODQlODAlRTElODUlQTUlRTElODYlQUIlRTElODQlODYlRTElODUlQUUlRTElODYlQUYlMjAlRTElODQlODklRTElODUlQTIlRTElODYlQjclRTElODQlOTElRTElODUlQjMlRTElODYlQUZfJUUxJTg0JThFJUUxJTg1JUExJUUxJTg2JUJDJUUxJTg0JTg2JUUxJTg1JUFFJUUxJTg2JUFCTy1JOVA5S3ROZ2wubndj",
  //   renderer: "forge",
  // },
  // {
  //   id: 7,
  //   name: "NWC: 흑막이 벽",
  //   fileType: "nwc",
  //   urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlOTIlRTElODUlQjMlRTElODYlQjAlRTElODQlODYlRTElODUlQTElRTElODYlQTglRTElODQlOEIlRTElODUlQjUtdm5WZEFOZi1qLm53Yw",
  //   renderer: "forge",
  // },
  // {
  //   id: 8,
  //   name: "glTF: Sponza",
  //   fileType: "gltf",
  //   urn: "Sponza/glTF/Sponza.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 9,
  //   name: "glTF: Sci Fi Helmet",
  //   fileType: "gltf",
  //   urn: "SciFiHelmet/glTF/SciFiHelmet.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 10,
  //   name: "glTF: IridescenceMetallicSpheres",
  //   fileType: "gltf",
  //   urn: "IridescenceMetallicSpheres/glTF/IridescenceMetallicSpheres.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 11,
  //   name: "glTF: Virtual City",
  //   fileType: "gltf",
  //   urn: "VC/glTF/VC.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 12,
  //   name: "glTF: Brain Stem",
  //   fileType: "gltf",
  //   urn: "BrainStem/glTF/BrainStem.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 13,
  //   name: "glTF: Buggy",
  //   fileType: "gltf",
  //   urn: "Buggy/glTF/Buggy.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 14,
  //   name: "glTF: Alpha Blend Mode Test",
  //   fileType: "gltf",
  //   urn: "AlphaBlendModeTest/glTF/AlphaBlendModeTest.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 15,
  //   name: "glTF: Random Bricks Thick", // https://creazilla.com/sections/3-3d-models/tags/649-gltf
  //   fileType: "gltf",
  //   urn: "random-bricks-thick-3d-model/random_bricks_thick_4k.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 16,
  //   name: "glTF: White Sandstone Bricks",
  //   fileType: "gltf",
  //   urn: "white-sandstone-bricks-3d-model/white_sandstone_bricks_03_4k.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 17,
  //   name: "glTF: Littlest Tokyo",
  //   fileType: "gltf",
  //   urn: "LittlestTokyo/LittlestTokyo.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 18,
  //   name: "OURMODEL: SMALL BUILDING",
  //   fileType: "gltf",
  //   urn: "SmallBuilding/smallBuilding.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 19,
  //   name: "OURMODEL: HOME BUILDING",
  //   fileType: "gltf",
  //   urn: "HomeBuilding/homeBuilding.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 20,
  //   name: "OURMODEL:LARGE BUILDING",
  //   fileType: "gltf",
  //   urn: "LargeBuilding/largeBuilding.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 21,
  //   name: "OUR MODEL:ONE STORY",
  //   fileType: "gltf",
  //   urn: "OneStory/oneStory.gltf",
  //   renderer: "forge",
  // },
  // {
  //   id: 19,
  //   name: "glTF: Littlest Tokyo",
  //   fileType: "gltf",
  //   urn: "LittlestTokyo/LittlestTokyo.gltf",
  //   renderer: "three",
  // },
];

export function Content() {
  const dispatch = useAppDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(() => {
    dispatch(setUrn3D(data[0].urn));
  }, [dispatch]);
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      aria-label="contacts"
    >
      {data.map((item) => {
        const handleClick = () => {
          dispatch(setUrn3D(item.urn));
          setSelectedIndex(item.id);
        };
        return (
          <ListItem disablePadding key={item.id}>
            <ListItemButton
              onClick={handleClick}
              selected={selectedIndex === item.id}
            >
              <ListItemIcon>
                {item.fileType === "revit" ? (
                  <StarIcon />
                ) : (
                  <BrightnessLowIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        );
      })}
      {/* <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <StarIcon />
          </ListItemIcon>
          <ListItemText primary="Chelsea Otakan" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText inset primary="Eric Hoffman" />
        </ListItemButton>
      </ListItem> */}
    </List>
  );
}
