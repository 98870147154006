import React from "react";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Home, Extension } from "./views";
import { RootProvider } from "./contexts";
import { Layout } from "components/Layout";

function App() {
  return (
    <RootProvider>
      <Layout>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/extension" element={<Extension />} />
        </Routes>
      </Layout>
    </RootProvider>
  );
}

export default App;
